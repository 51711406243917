<template>
  <div class="actions">
    <img
      :src="photo"
      alt=""
      class="avatar-md rounded-circle"
    >
  </div>
</template>



<script>
const animalPhoto = require('../../../assets/img/animalPlaceholder.png')
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    methods: {
        deletingAnimal(){

        }
    },
    computed: {
      photo(){
        if(this.object !== null && this.object.photo !== null) { let p = JSON.parse(this.object.photo); return p.url }
        return animalPhoto
      }
    }

}

</script>
<style lang="scss">
.actions{
    a{
        
    }
}
</style>